<template>
    <div>扫描成功，正在跳转...
 
  </div>
  
</template>

<script>
import homeHeader from '@/components/HomeHeader.vue'
export default {
  name: "loginwx",
  data() {
    return {
      homeVisible:false,
    };
  },
  created () {
    this.loginbywx(this.$route.query.state, this.$route.query.code );
  },
  watch: {
    $route(to, from) {
      this.loginbywx(this.$route.query.state, this.$route.query.code );
    },
  },
  components: {
 
  },
  methods: {
    loginbywx(state, code ) {
      let that = this;
      this.$axios({
        method: "get",
        url: this.$store.state.baseUrl + "auth/login/loginOpenid",
        params: {
          //get这里应为params //请求参数
          state: state,
          code: code
        },
      }).then((res) => {
        console.log(JSON.stringify(res.data) )
        if (res.data.code === "200") { //后台中心微信登录
            that.$cookies.set("admin_token", "sample_token");
            that.$cookies.set("v_username", res.data.userno); //用户编码
            that.$cookies.set("v_usernm", res.data.usernm); //用户名称
            that.$cookies.set("v_password", res.data.enpassword); //加密后的密码
            that.$cookies.set("idseq", res.data.idseq);
            that.$router.replace("/dashboard/main");
        }else if (res.data.code === "220") { //会员中心微信登录 
            that.$cookies.set("v_telno", res.data.userno); //用户编码
            that.$cookies.set("v_enpwd", res.data.enpassword); //加密后的密码
            that.$cookies.set("v_idseq", res.data.idseq);
            if (state=='carpay'){ //购物车
              that.$router.replace("/ordmgt/carlist");
            }else{  
              that.$router.replace("/member/basic_info");
            }
        }else if (res.data.code === "201") { //微信绑定
            that.$router.replace("/dashboard/main");           
        } else {
            that.$alert(res.data.result, '提示', {
                confirmButtonText: '确定',
                showClose:false,
                type:'info',
                callback: action => {
                  that.$router.replace("/frontLogin")
                }
            })          
        }
      });
    },
  },
};
</script>
  
<style>
</style>